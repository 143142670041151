<template>
    <div class="row">
        <div class="col-md col-6 mb-4">
            <div class="card dveb-white-card">
                <div class="card-boy">
                    <div class="infos">
                        <div class="text">
                            <h6>Tüm Müşteriler</h6>
                            <span>642</span>
                        </div>
                        <div class="btn btn-icon btn-circle btn-light-twitter mr-2">
                            <i class="flaticon2-user"></i>
                        </div>                    </div>
                </div>
            </div>
        </div>
        <div class="col-md col-6 mb-4">
            <div class="card dveb-white-card">
                <div class="card-boy">
                    <div class="infos">
                        <div class="text">
                            <h6>Tüm Müşteriler</h6>
                            <span>642</span>
                        </div>
                        <div class="btn btn-icon btn-circle btn-light-twitter mr-2">
                            <i class="flaticon2-user"></i>
                        </div>                    </div>
                </div>
            </div>
        </div>
        <div class="col-md col-6 mb-4">
            <div class="card dveb-white-card">
                <div class="card-boy">
                    <div class="infos">
                        <div class="text">
                            <h6>Tüm Müşteriler</h6>
                            <span>642</span>
                        </div>
                        <div class="btn btn-icon btn-circle btn-light-twitter mr-2">
                            <i class="flaticon2-user"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md col-6 mb-4">
            <div class="card dveb-white-card">
                <div class="card-boy">
                    <div class="infos">
                        <div class="text">
                            <h6>Tüm Müşteriler</h6>
                            <span>642</span>
                        </div>
                        <div class="btn btn-icon btn-circle btn-light-twitter mr-2">
                            <i class="flaticon2-user"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md col-6 mb-4">
            <div class="card dveb-white-card">
                <div class="card-boy">
                    <div class="infos">
                        <div class="text">
                            <h6>Tüm Müşteriler</h6>
                            <span>642</span>
                        </div>
                        <div class="btn btn-icon btn-circle btn-light-twitter mr-2">
                            <i class="flaticon2-user"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    name: 'info-cards',
    mounted(){
        fetch("https://mulkpro.dvebdemo.com.tr/api/mulkler_list?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmdWxsTmFtZSI6IkZhdGloQ2FtY2lvZ2x1IiwiZW1haWwiOiJibGFua0BibGFuay5jb20udHIiLCJyb2xlIjoiU2lzdGVtIFlcdTAwZjZuZXRpY2lzaSJ9.0nCobz_-Ukm1wV1Capq3Khe_M3LuMlsbBM8XfATo7ok",{
            headers:{
                "Authorization" : "bXVsazpwcm8="
            }
        }).then(response => response.json())

    }
};
</script>

<style lang="scss">
    .infos{
        display: flex;
        justify-content: space-between;
        .text{
            display: flex;
            flex-direction: column;
            h6{
               opacity: .5;
            }
            span{
                font-size: 1.5rem;
                font-weight: 600;
            }
        }
    }

</style>